import $ from "jquery";
import { isOnMobile } from "../../_utils";
import { Modal } from "../_modal";

export class Page {

	static PAGE_CLASS;

	constructor() {
		this.portalsData = $(".js-portals-data").data();
		this._modals = [];
		this.addModal(new Modal(".js-language-modal", ".js-open-language-modal-button"));
		this._addCloseModalsActionOnEscapeKey();
		this._removeBackgroundImageOnMobile();
		this.focusFirstFieldWithErrors();
	}

	static isCurrentPage() {
		return $("html").hasClass(this.PAGE_CLASS);
	}

	enableReloadOnReturn() {
		const perfEntries = performance.getEntriesByType("navigation");
		if (perfEntries[0].type === "back_forward") {
			window.location.reload();
		}
	}

	addModal(modal) {
		this._modals.push(modal);
	}

	_addCloseModalsActionOnEscapeKey() {
		$(document).on("keyup", (event) => {
			if (event.key === "Escape") {
				this._modals.forEach(modal => {
					if (modal.isOpened()) {
						modal.close();
					}
				});
			}
		});
	}

	_removeBackgroundImageOnMobile() {
		if (isOnMobile()) {
			$(".js-main-content").css("background-image", "none");
		}
	}

	focusFirstFieldWithErrors() {
		const $firstFieldWithErrors = $(".form-field--with-errors input, .form-field--with-errors select").first();
		if ($firstFieldWithErrors.length) {
			$firstFieldWithErrors.focus();
			$firstFieldWithErrors.get(0).scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center"
			});
		}
	}

}
