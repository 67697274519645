import $ from "jquery";
import axios from "axios";
import { Page } from "./_page";

export class ShoppingCartPage extends Page {

	static PAGE_CLASS = "shopping-cart-page";

	constructor() {
		super();
		this._prepareDeleteShoppingCartItemButton();
	}

	_prepareDeleteShoppingCartItemButton() {
		$(".js-delete-cart-item-button").on("click", function() {
			const $button = $(this);
			const URL = $button.data("deleteShoppingCartItemUrl");
			axios.delete(URL).then(_ => window.location.reload());
		});
	}
}
