import $ from "jquery";

export class Modal {

	static MODALS_CONTAINER_SELECTOR = ".js-modals-container";

	constructor(modalSelector, openModalSelector) {
		this.$modalElement = this._findModalElement(modalSelector);
		if (openModalSelector) {
			this._prepareOpenModalElement(openModalSelector);
		}
		this.$modalElement.find(".js-modal-close").on("click", () => this.close());
	}

	_findModalElement(selector) {
		const $modalElement = $(Modal.MODALS_CONTAINER_SELECTOR).find(selector);
		if (!$modalElement.length) {
			throw Error(
				`Modal element with selector "${selector}" does not exist inside ${Modal.MODALS_CONTAINER_SELECTOR}`
			);
		}
		return $modalElement;
	}

	_prepareOpenModalElement(openModalSelector) {
		const $openModalElement = $(openModalSelector);
		if (!$openModalElement.length) {
			throw Error(
				`Element with "${openModalSelector}" selector for opening the 
                "${openModalSelector}" modal does not exist`
			);
		}
		$openModalElement.on("click", () => this.open());
	}

	isOpened() {
		return this.$modalElement.hasClass("opened");
	}

	open() {
		this.$modalElement.siblings(".opened").removeClass("opened");
		this.$modalElement.addClass("opened");
		$("header, main, footer").addClass("hidden-on-mobile");
	}

	close() {
		this.$modalElement.removeClass("opened");
		$("header, main, footer").removeClass("hidden-on-mobile");
	}

}
