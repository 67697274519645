import { Page } from "./_page";
import { ConsumerReservations } from "../consumer_reservations";


export class PaymentOverviewPage extends Page {

	static PAGE_CLASS = "js-payment-overview";

	constructor() {
		super();
		ConsumerReservations.prepareCards();
	}

}
