import $ from "jquery";
import { areSameDates, incrementDateByHours } from "../_utils";
import { FormService } from "../_form_service";
import { TimeWidget } from "./_time_widget";
import ClipboardJS from "clipboard";
import { HIDDEN_ELEMENT_CLASS } from "../_common";

export class ReservationFormService extends FormService {

	static SHARE_BTN_SELECTOR = ".js-share";
	static ARRIVAL_DATE_INPUT_SELECTOR = "input[name=arrival_date]";
	static ARRIVAL_TIME_INPUT_SELECTOR = "input[name=arrival_time]";
	static EXIT_DATE_INPUT_SELECTOR = "input[name=exit_date]";
	static EXIT_TIME_INPUT_SELECTOR = "input[name=exit_time]";

	static SELECTED_LICENSE_PLATE_CSS_CLASS = "license-plate--selected";

	constructor() {
		super(".js-reservation-form");
	}

	_setup(formSelector) {
		super._setup(formSelector);
		this.timeWidgets = $(".js-time-widget").toArray().map(element => new TimeWidget(element));
		this.$dateInputs = this._getDateInputs();
		this._prepareDateFields();
		this.currentArrival = this._getSelectedArrival();
		this.currentExit = this._getSelectedExit();
		this._prepareDateAndTimeFieldHandlers();
		this._prepareShareNewBookingButton();
	}

	_getDateInputs() {
		const dateInputSelectors = [
			ReservationFormService.ARRIVAL_DATE_INPUT_SELECTOR,
			ReservationFormService.EXIT_DATE_INPUT_SELECTOR
		];
		return this.$form.find(dateInputSelectors.join(", "));
	}

	_prepareDateFields() {
		this.$dateInputs.datepicker({
			dateFormat: "dd-mm-yy",
			onSelect: function() {
				$(this).trigger("input");
			}
		});
	}

	_prepareDateAndTimeFieldHandlers() {
		const $temporalInputs = this._getTemporalInputs();
		$temporalInputs.on("input", async(event) => {
			await this._handleDateTimeChange();
			const datetimeChangeEvent = new $.Event("datetime_change");
			datetimeChangeEvent.input = event.target;
			this.$form.trigger(datetimeChangeEvent);
		});
	}

	_getTemporalInputs() {
		const temporalInputSelectors = [
			ReservationFormService.ARRIVAL_DATE_INPUT_SELECTOR,
			ReservationFormService.EXIT_DATE_INPUT_SELECTOR,
			ReservationFormService.ARRIVAL_TIME_INPUT_SELECTOR,
			ReservationFormService.EXIT_TIME_INPUT_SELECTOR
		];
		return this.$form.find(temporalInputSelectors.join(", "));
	}

	_handleDateTimeChange = async() => {
		const newArrival = this._getSelectedArrival();
		const newExit = this._getSelectedExit();
		let adjustedExit = null;

		if (newArrival.getTime() !== this.currentArrival.getTime() || newArrival >= newExit) {
			adjustedExit = this._adjustExit(newArrival, newExit);
		}

		if (newArrival.getTime() !== this.currentArrival.getTime()) {
			this.currentArrival = newArrival;
		}

		if (adjustedExit) {
			this._setExit(adjustedExit);
		} else if (newExit.getTime() !== this.currentExit.getTime()) {
			this._setExit(newExit);
		}
	};

	getFormData() {
		const data = super.getFormData();
		data.arrival_date = this._getSelectedDateValue(ReservationFormService.ARRIVAL_DATE_INPUT_SELECTOR);
		data.arrival_time = this._getSelectedTimeValue(ReservationFormService.ARRIVAL_TIME_INPUT_SELECTOR);
		data.exit_date = this._getSelectedDateValue(ReservationFormService.EXIT_DATE_INPUT_SELECTOR);
		data.exit_time = this._getSelectedTimeValue(ReservationFormService.EXIT_TIME_INPUT_SELECTOR);
		const $selectedLicensePlateField = this.$form.find(".license-plate--selected");
		if ($selectedLicensePlateField.length) {
			data.existing_license_plate = $selectedLicensePlateField.data("licensePlateValue");
		}
		return data;
	}

	_getSelectedArrival() {
		const formData = this.getFormData();
		return new Date(`${formData.arrival_date} ${formData.arrival_time}`);
	}

	_getSelectedExit() {
		const formData = this.getFormData();
		return new Date(`${formData.exit_date} ${formData.exit_time}`);
	}

	/**
	 * Expects the input value to be in format "DD-MM-YYYY",
	 * and returns it in ISO supported format "YYYY-MM-DD"
	 */
	_getSelectedDateValue(inputSelector) {
		const original = this.$form.find(inputSelector).val();
		if (!original) return "";
		return original.split("-").reverse().join("-");
	}

	/**
	 * Returns modified exit date object that keeps the original duration
	 * between arrival and exit dates
	 */
	_adjustExit(newArrival) {
		const originalDuration = this.currentExit - this.currentArrival;
		return incrementDateByHours(newArrival, originalDuration / 1000 / 60 / 60);
	}

	/**
	 * Sets the new exit date and time in the form input fields
	 * and updates the current exit date object.
	 */
	_setExit(newExit) {
		const $exitTimeInput = this.$form.find(ReservationFormService.EXIT_TIME_INPUT_SELECTOR);
		const $exitDateInput = this.$form.find(ReservationFormService.EXIT_DATE_INPUT_SELECTOR);
		if (!areSameDates(this.currentExit, newExit)) {
			this._triggerPulseEffect($exitDateInput);
		} else if (newExit.getTime() !== this.currentExit.getTime()) {
			this._triggerPulseEffect($exitTimeInput);
		}

		this.currentExit = newExit;

		// Set the new exit date and time in the form input fields:
		$exitTimeInput.val(this.currentExit.toTimeString().substring(0, 5));
		$exitDateInput.val(this._createDateString(this.currentExit));
	}

	_triggerPulseEffect($element) {
		$element.addClass("pulse-effect");

		$element.on("animationend", function() {
			$element.removeClass("pulse-effect");
		});
	}

	/**
	 * Returns a date string in format "DD-MM-YYYY"
	 * suitable for the date input field
	 */
	_createDateString(date) {
		const dateYear = `${date.getFullYear()}`;
		let dateMonth = date.getMonth() + 1;
		if (dateMonth < 10) {
			dateMonth = "0" + dateMonth;
		}
		const dateDay = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
		return `${dateDay}-${dateMonth}-${dateYear}`;
	}

	_getSelectedTimeValue(inputSelector) {
		return this.$form.find(inputSelector).val();
	}

	_prepareShareNewBookingButton() {
		$(".js-share-new-booking__button").on("click", () => {
			if (navigator.share) this._handleShareNewBookingButtonClickOnMobile();
			else this._handleShareNewBookingButtonClickOnDesktop();
		});
	}

	_handleShareNewBookingButtonClickOnMobile() {
		navigator.share({
			title: document.title,
			url: this._createShareNewBookingURL()
		});
		this._displayShareNewBookingConfirmationMessage();
	}

	_handleShareNewBookingButtonClickOnDesktop() {
		// eslint-disable-next-line no-new
		new ClipboardJS(".js-share-new-booking__button", {
			text: () => this._createShareNewBookingURL()
		});
		this._displayShareNewBookingConfirmationMessage();
	}

	_displayShareNewBookingConfirmationMessage() {
		const $confirmationMessage = $(".js-share-new-booking__confirmation-message");
		$confirmationMessage.removeClass(HIDDEN_ELEMENT_CLASS);
		setTimeout(() => {
			$confirmationMessage.fadeOut("slow", () => {
				$confirmationMessage.addClass(HIDDEN_ELEMENT_CLASS);
				$confirmationMessage.removeAttr("style");
			});
		}, 3000);
	}

	_createShareNewBookingURL() {
		const URL = this.$form.data("shareUrl");
		let urlParams = `arrival=${this._getSelectedDateValue(ReservationFormService.ARRIVAL_DATE_INPUT_SELECTOR)}T`;
		urlParams += `${this._getSelectedTimeValue(ReservationFormService.ARRIVAL_TIME_INPUT_SELECTOR)}`;
		urlParams += `&exit=${this._getSelectedDateValue(ReservationFormService.EXIT_DATE_INPUT_SELECTOR)}T`;
		urlParams += `${this._getSelectedTimeValue(ReservationFormService.EXIT_TIME_INPUT_SELECTOR)}`;
		urlParams += `&location_settings_id=${this.$form.find("select[name=location_settings]").val()}`;
		return `${URL}?${encodeURI(urlParams)}`;
	}

	disableForm() {
		const $formField = $(".js-form-field");
		$formField.find("input").attr("readonly", true).css("cursor", "wait");
		$formField.find("select").css({ "pointer-events": "none", cursor: "wait" });
		this.$dateInputs.datepicker("option", "showOn", "off").css("cursor", "wait");
		this.timeWidgets.forEach(timeWidget => timeWidget.disable());

		const $submitButton = $(".js-reservation-form-submit-button");
		$submitButton.attr("disabled", true).css("cursor", "wait");
	}
}
