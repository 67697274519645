import $ from "jquery";
import { isOnMobile } from "../../_utils";
import { reservationCancellationService } from "./_reservation_cancellation_service";


export class ConsumerReservations {

	static prepareCards() {
		const instance = new ConsumerReservations();
		instance.prepareMenuButtonClickHandler();
		instance.prepareMenuItemClickHandler();
		instance.prepareCardClickHandler();
		instance.prepareOuterClickHandler();
		reservationCancellationService.prepareDecisionOverlay();
	}

	prepareMenuButtonClickHandler() {
		const self = this;
		$(".js-consumer-reservation-card-menu-button").click(function() {
			!isOnMobile() && self._handleMenuButtonClick($(this));
		});
	}

	_handleMenuButtonClick($button) {
		const $clickedButtonMenu = $button.parent(".js-consumer-reservation-card-menu");
		const $otherMenus = $(".js-consumer-reservation-card-menu").not($clickedButtonMenu);
		this._deactivateDesktopMenu($otherMenus);
		if ($button.hasClass("active")) {
			this._deactivateDesktopMenu($clickedButtonMenu);
		} else {
			this._activateDesktopMenu($clickedButtonMenu);
		}
	}

	_deactivateDesktopMenu($menu) {
		$menu.find(".js-consumer-reservation-card-menu-button").removeClass("active");
		$menu.find(".js-consumer-reservation-card-menu-items").removeClass("active");
		$menu.find(".js-consumer-reservation-card-menu-item").removeClass("active");
	}

	_activateDesktopMenu($menu) {
		const $menuButton = $menu.find(".js-consumer-reservation-card-menu-button");
		const $menuItems = $menu.find(".js-consumer-reservation-card-menu-items");

		$menuButton.addClass("active");
		$menuItems.addClass("active");

		const minMenuSpace = $menuItems.width() + 10;
		const hasEnoughMenuSpace = $(window).width() - $menuButton.offset().left >= minMenuSpace;
		if (hasEnoughMenuSpace) {
			$menuItems.css({ right: "unset", left: 0 });
		} else {
			$menuItems.css({ right: 0, left: "unset" });
		}
	}

	prepareMenuItemClickHandler() {
		const self = this;
		$(".js-consumer-reservation-card-menu-item").click(function() {
			const $menuItem = $(this);
			$menuItem.addClass("active");
			$menuItem.siblings().removeClass("active");
			const $menu = $menuItem.parents(".js-consumer-reservation-card-menu");
			if (isOnMobile()) {
				self._deactivateMobileMenu($menu);
			} else {
				self._deactivateDesktopMenu($menu);
			}
			const menuItemURL = $menuItem.data("url");
			if (menuItemURL) {
				window.open(menuItemURL, "_blank");
			}
		});
	}

	prepareCardClickHandler() {
		const self = this;
		$(".js-consumer-reservation-card").click(function() {
			isOnMobile() && self._handleCardClick($(this));
		});
	}

	_handleCardClick($card) {
		const $clickedMenu = $card.siblings(".js-consumer-reservation-card-menu");
		const $otherMenu = $(".js-consumer-reservation-card-menu").not($clickedMenu);

		this._deactivateMobileMenu($otherMenu);

		if ($clickedMenu.hasClass("js-expended")) {
			this._deactivateMobileMenu($clickedMenu);
		} else {
			this._activateMobileMenu($clickedMenu);
		}
	}

	_deactivateMobileMenu($menu) {
		$menu.removeClass("js-expended");
		$menu.find(".js-consumer-reservation-card-menu-items").slideUp(200, function() {
			$menu.find(".js-consumer-reservation-card-menu-item").removeClass("active");
		});
	}

	_activateMobileMenu($menu) {
		$menu.addClass("js-expended");
		$menu.find(".js-consumer-reservation-card-menu-items").slideDown(400, function() {
			const $card = $menu.siblings(".js-consumer-reservation-card");
			$card.get(0).scrollIntoView({ behavior: "smooth", inline: "center" });
		});
	}

	prepareOuterClickHandler() {
		const self = this;
		$(window).click(function(event) {
			const hasClickedOnCard = (
				$(event.target).hasClass("js-consumer-reservation")
				|| !!$(event.target).parents(".js-consumer-reservation").length
			);
			!hasClickedOnCard && self._handleOuterClick();
		});
	}

	_handleOuterClick() {
		if (isOnMobile()) {
			this._deactivateMobileMenu($(".js-consumer-reservation-card-menu"));
		} else {
			this._deactivateDesktopMenu($(".js-consumer-reservation-card-menu"));
		}
	}

}
