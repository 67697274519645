import $ from "jquery";
import { HIDDEN_ELEMENT_CLASS } from "../_common";

export class CollapsableCardService {

	constructor() {
		$(".js-dropdown-header").on("click", event => {
			const $dropdownHeader = $(event.target).hasClass("js-dropdown-header")
				? $(event.target)
				: $(event.target).closest(".js-dropdown-header");
			$dropdownHeader.find(".js-dropdown-caret").toggleClass("rotate");
			$dropdownHeader.next().toggleClass(HIDDEN_ELEMENT_CLASS);
		});
	}

}
