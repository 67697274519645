import $ from "jquery";
import axios from "axios";
import { isOnMobile } from "../../_utils";
import { HIDDEN_ELEMENT_CLASS } from "../../_common";


export const reservationCancellationService = {

	prepareDecisionOverlay() {
		const self = this;
		$(".js-open-cancel-reservation-decision-overlay").click(function() {
			self._openDecisionOverlay(this);
		});
		$(".js-close-cancel-reservation-decision-overlay").click(function() {
			self._closeDecisionOverlay(this);
			const $consumerReservation = $(this).parents(".js-consumer-reservation");
			$consumerReservation.find(".js-cancel-reservation-error").css("display", "none");
		});
		$(".js-cancel-reservation").click(async function() {
			await self._cancelReservation(this);
		});
	},

	_openDecisionOverlay(clickedElement) {
		const $consumerReservation = $(clickedElement).parents(".js-consumer-reservation");
		!isOnMobile() && $consumerReservation.find(".js-consumer-reservation-card-menu-button").hide();
		$consumerReservation.find(".js-cancel-reservation-decision").show();
		$consumerReservation.find(".js-consumer-reservation-card").addClass("consumer-reservation-card--covered");
	},

	_closeDecisionOverlay(clickedElement) {
		const $consumerReservation = $(clickedElement).parents(".js-consumer-reservation");
		!isOnMobile() && $consumerReservation.find(".js-consumer-reservation-card-menu-button").show();
		$consumerReservation.find(".js-cancel-reservation-decision").hide();
		$consumerReservation.find(".js-consumer-reservation-card").removeClass("consumer-reservation-card--covered");
		this._hideCancellationConfirmation($consumerReservation);
	},

	async _cancelReservation(clickedElement) {
		const $consumerReservation = $(clickedElement).parents(".js-consumer-reservation");
		this._activateCancellationLoader($consumerReservation);
		try {
			await axios.delete($consumerReservation.data("cancelReservationUrl"));
			this._handleSuccessfullyCanceledReservation($consumerReservation);
		} catch (error) {
			this._handleReservationCancellationError($consumerReservation, error);
		}
	},

	_handleSuccessfullyCanceledReservation($consumerReservation) {
		$consumerReservation.find(
			".js-consumer-reservation-card-menu, .js-consumer-reservation-price, .js-consumer-reservation-status-flag"
		).remove();
		this._showCancellationResult($consumerReservation, ".js-cancel-reservation-confirmation");
		$consumerReservation.find(".js-cancelled-status-flag").removeClass(HIDDEN_ELEMENT_CLASS);
	},

	_handleReservationCancellationError($consumerReservation, error) {
		if (error.response?.status === 400) {
			this._showCancellationResult($consumerReservation, ".js-cancel-reservation-error");
		}
	},

	_activateCancellationLoader($consumerReservation) {
		$consumerReservation.find(".js-cancel-reservation-buttons").hide();
		$consumerReservation.find(".js-cancel-reservation-loader").css("display", "flex");
	},


	_showCancellationResult($consumerReservation, resultContainerSelector) {
		this._deactivateCancellationLoader($consumerReservation);
		$consumerReservation.find(resultContainerSelector).css("display", "flex");
	},

	_deactivateCancellationLoader($consumerReservation) {
		$consumerReservation.find(".js-cancel-reservation-buttons").show();
		$consumerReservation.find(".js-cancel-reservation-loader").hide();
	},

	_hideCancellationConfirmation($consumerReservation) {
		$consumerReservation.find(".js-cancel-reservation-confirmation").hide();
	}

};
