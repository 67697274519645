import $ from "jquery";
import { Page } from "./_page";
import { LicensePlatesFormService } from "../../_license_plates_form_service";
import { FormService } from "../../_form_service";
import axios from "axios";
import { preparePasswordFields } from "../_utils";

export class RegistrationPage extends Page {

	static PAGE_CLASS = "register-page";

	constructor() {
		super();
		this._prepareFormHandlers();
		preparePasswordFields();
	}

	_prepareFormHandlers() {
		const personalInfoFormService = new FormService(".js-registration-form");
		const licensePlatesFormService = new LicensePlatesFormService();
		licensePlatesFormService.allowEmptyForm();

		const self = this;
		$(".js-create-account-button").on("click", async function() {
			$(this).prop("disabled", true);
			await personalInfoFormService.submit();
			await licensePlatesFormService.submit();
			if (!personalInfoFormService.hasErrors() && !licensePlatesFormService.hasErrors()) {
				const registrationData = personalInfoFormService.getFormData();
				registrationData.license_plates = Object.values(licensePlatesFormService.getFormData());
				try {
					await axios.post(window.location.href, registrationData);
					window.location.href = personalInfoFormService.$form.data("successUrl");
				} catch (error) {
					console.error("Error thrown while trying to register the user:", error);
				}
			} else {
				$(this).prop("disabled", false);
				self.focusFirstFieldWithErrors();
			}
		});
	}

}
