import $ from "jquery";
import { Page } from "./_page";
import { CollapsableCardService } from "../_collapsable_card_service";
import PaymentFormService from "../../_payment_form_service";

export class CheckoutPaymentPage extends Page {
	static PAGE_CLASS = "checkout-payment-page";

	constructor() {
		super();
		// eslint-disable-next-line no-new
		new CollapsableCardService();
		const paymentFormService = new PaymentFormService();
		paymentFormService.initEventHandlers();

		$(".js-continue-checkout-button").on("click", function() {
			$(".js-checkout-payment-form").submit();
		});
	}
}
