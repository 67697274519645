import $ from "jquery";
import axios from "axios";
import { Page } from "./_page";
import { FormService } from "../../_form_service";
import { HIDDEN_ELEMENT_CLASS } from "../../_common";

export class LicensePlatesPage extends Page {

	static PAGE_CLASS = "license-plates-page";

	static LICENSE_PLATE_SELECTOR = ".js-license-plate";
	static NEW_LICENSE_PLATE_FIELD_SELECTOR = ".js-new-license-plate-field";
	static SELECTED_FIELD_OPTION_CSS_CLASS = "primary-license-plate-option--selected";

	constructor() {
		super();
		this.enableReloadOnReturn();
		this.licensePlatesFormService = new FormService(".js-license-plates-form");
		this._prepareDeleteLicensePlateButtons();
		this._preparePrimaryLicensePlateField();
		this._prepareNewPlateField();
		this._prepareFormSubmit();
		this._prepareAddLicensePlateButton();
	}

	_prepareDeleteLicensePlateButtons() {
		const page = this;
		$(".js-delete-license-plate-btn").on("click", async function() {
			const deleteLicensePlateURL = $(this).data("deleteLicensePlateUrl");
			if (deleteLicensePlateURL) {
				await page._deleteLicensePlate(deleteLicensePlateURL);
			}
			if (deleteLicensePlateURL || $(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR).length > 1) {
				$(this).closest(LicensePlatesPage.LICENSE_PLATE_SELECTOR).remove();
			} else {
				$(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR).addClass(HIDDEN_ELEMENT_CLASS);
			}
		});
	}

	async _deleteLicensePlate(url) {
		try {
			await axios.delete(url);
		} catch (error) {
			if (error.response.status !== 502) {
				throw error;
			}
		}
	}

	_preparePrimaryLicensePlateField() {
		const page = this;
		page.licensePlatesFormService.$form.find("input[type=checkbox]").on("change", function() {
			$(`.${LicensePlatesPage.SELECTED_FIELD_OPTION_CSS_CLASS}`)
				.removeClass(LicensePlatesPage.SELECTED_FIELD_OPTION_CSS_CLASS);
			page.licensePlatesFormService.$form.find("input[type=checkbox]").prop("checked", false);
			$(this).prop("checked", true);
			$(this)
				.closest(LicensePlatesPage.LICENSE_PLATE_SELECTOR)
				.addClass(LicensePlatesPage.SELECTED_FIELD_OPTION_CSS_CLASS);
		});
	}

	_prepareNewPlateField() {
		$(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR).find("input[type=text]").on("input", function(event) {
			$(this)
				.closest(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR)
				.find("input[type=checkbox]")
				.val(event.target.value);
		});
	}

	_prepareFormSubmit() {
		const page = this;
		const validateLicensePlatesURL = page.licensePlatesFormService.$form.data("validateLicensePlatesUrl");
		page.licensePlatesFormService.$form.on("submit", async function(event) {
			event.preventDefault();
			await page.licensePlatesFormService.submit(validateLicensePlatesURL);
			if (!page.licensePlatesFormService.hasErrors()) {
				await page.licensePlatesFormService.submit(window.location.href);
				if (!page.licensePlatesFormService.hasErrors()) {
					window.location.href = page.licensePlatesFormService.$form.data("successUrl");
				}
			}
		});
	}

	_prepareAddLicensePlateButton() {
		const page = this;
		page.licensePlatesFormService.$form.find(".js-add-license-plate").on("click", function() {
			if ($(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR).hasClass(HIDDEN_ELEMENT_CLASS)) {
				$(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR).removeClass(HIDDEN_ELEMENT_CLASS);
			} else {
				page._createAnotherNewLicensePlateField();
			}
		});
	}

	_createAnotherNewLicensePlateField() {
		const $lastNewLicensePlateField = $(LicensePlatesPage.NEW_LICENSE_PLATE_FIELD_SELECTOR).last();
		const lastNewLicensePlateFieldName = $lastNewLicensePlateField.find("input[type=text]").attr("name");
		const lastNewLicensePlateFieldNumber = parseInt(
			lastNewLicensePlateFieldName.replace("new_license_plate_", "")
		);
		const newLicensePlateFieldNumber = lastNewLicensePlateFieldNumber + 1;
		const $newLicensePlateField = $lastNewLicensePlateField.clone(true, true);
		$newLicensePlateField.removeClass(LicensePlatesPage.SELECTED_FIELD_OPTION_CSS_CLASS);
		$newLicensePlateField.find("input[type=checkbox]").prop("checked", false);
		$newLicensePlateField.removeClass(FormService.FORM_FIELD_WITH_ERRORS_CSS_CLASS);
		$newLicensePlateField.find(`.${FormService.FORM_FIELD_ERROR_CSS_CLASS}`).remove();
		const $inputElement = $newLicensePlateField.find("input[type=text]");
		$inputElement.attr("name", `new_license_plate_${newLicensePlateFieldNumber}`);
		$inputElement.val("");
		$lastNewLicensePlateField.after($newLicensePlateField);
	}
}
