import { Modal } from "./_modal";
import { FormService } from "../_form_service";
import { HIDDEN_ELEMENT_CLASS } from "../_common";
import $ from "jquery";

export class PasswordForgottenModal extends Modal {

	constructor() {
		super(".js-password-forgotten-modal", ".js-open-password-forgotten-modal");
		this._preparePasswordForgottenForm();
	}

	_preparePasswordForgottenForm() {
		const passwordForgottenFormService = new FormService(".js-password-forgotten-form");
		passwordForgottenFormService.$form.on("submit", async function(event) {
			event.preventDefault();
			await passwordForgottenFormService.submit();
			if (!passwordForgottenFormService.hasErrors()) {
				passwordForgottenFormService.$form.addClass(HIDDEN_ELEMENT_CLASS);
				$(".js-password-forgotten-modal-confirmation").removeClass(HIDDEN_ELEMENT_CLASS);
			}
		});
	}

	close() {
		super.close();
		const passwordForgottenFormService = new FormService(".js-password-forgotten-form");
		passwordForgottenFormService.$form.removeClass(HIDDEN_ELEMENT_CLASS);
		$(".js-password-forgotten-modal-confirmation").addClass(HIDDEN_ELEMENT_CLASS);
		passwordForgottenFormService.$form.find("input[name=\"email\"]").val("");
	}

}
