import axios from "axios";
import { Page } from "./_page";
import $ from "jquery";
import {
	ONE_MINUTE_IN_MILLISECONDS,
	THREE_MINUTES_IN_MILLISECONDS,
	THREE_SECONDS_IN_MILLISECONDS
} from "../../_common";

export class CheckoutConfirmationPage extends Page {

	static PAGE_CLASS = "checkout-confirmation-page";

	constructor() {
		super();
		this.$content = $(".js-checkout-confirmation-content");
		this.isPolling = false;
		if (this.$content.data("isPending")) {
			this.pollingStart = this._getPollingStart();
			this.pollingInterval = setInterval(this._pollContent, THREE_SECONDS_IN_MILLISECONDS);
		} else {
			this.pollingStart = null;
			this.pollingInterval = null;
		}
	}

	_getPollingStart = () => {
		const pollingStart = localStorage.getItem("checkoutConfirmationPollingStart");
		if (pollingStart) {
			return parseInt(pollingStart);
		} else {
			const now = Date.now();
			localStorage.setItem("checkoutConfirmationPollingStart", now);
			return now;
		}
	};

	/**
	 * Polls the content of the current page to check if the payment
	 * status is still pending. If the payment status is no longer pending,
	 * the content is updated.
	 *
	 * After a certain amount of polling time, if the payment status is
	 * still pending, a specific message is displayed to the user, and
	 * the polling interval is increased.
	 */
	_pollContent = async() => {
		if (this.isPolling) {
			return;
		}

		this.isPolling = true;

		try {
			const response = await axios.get(window.location.href);
			const parser = new DOMParser();
			const doc = parser.parseFromString(response.data, "text/html");
			const targetContentElement = doc.querySelector(".js-checkout-confirmation-content");
			if (targetContentElement.getAttribute("data-is-pending").toLowerCase() !== "true") {
				this.$content.html(targetContentElement.innerHTML);
				localStorage.removeItem("checkoutConfirmationPollingStart");
				clearInterval(this.pollingInterval);
			}
			const elapsedTime = Date.now() - this.pollingStart;
			if (elapsedTime >= THREE_MINUTES_IN_MILLISECONDS) {
				this.$content.find(".js-is-this-taking-too-long").css("display", "block");
				clearInterval(this.pollingInterval);
				this.pollingInterval = setInterval(this._pollContent, ONE_MINUTE_IN_MILLISECONDS);
			}
		} catch (error) {
			console.error("Error fetching data");
		} finally {
			this.isPolling = false;
		}
	};

}
