import $ from "jquery";
import axios from "axios";
import { HIDDEN_ELEMENT_CLASS } from "../_common";

export class ConsumerTransactions {

	static LOADER_SELECTOR = ".js-consumer-transactions-loader";
	static LOAD_MORE_BUTTON_SELECTOR = ".js-consumer-transactions-button";
	static END_OF_LIST_SELECTOR = ".js-consumer-transactions-end-of-list";
	static INFINITE_SCROLL_MIN_SCREEN_WIDTH = 1250;

	static async initList() {
		this.nextPageURL = null;

		this._initClosedUntilFilter();

		const $originalLoader = $(this.LOADER_SELECTOR);
		this.$clonedLoader = $originalLoader.clone();
		$originalLoader.remove();

		const $originalButton = $(this.LOAD_MORE_BUTTON_SELECTOR);
		this.$clonedButton = $originalButton.clone();
		$originalButton.remove();

		const $originalEndOfList = $(this.END_OF_LIST_SELECTOR);
		this.$clonedEndOfList = $originalEndOfList.clone();
		$originalEndOfList.remove();

		this.listBottomObserver = this._createListBottomObserver();

		this.$listContainer = $(".js-consumer-transactions");
		this.initialPageURL = this.$listContainer.data("consumerTransactionsUrl");
		await this._loadListItems(this.initialPageURL);
	}

	static _initClosedUntilFilter() {
		const $closedUntilFilter = $(".js-consumer-transactions-closed-until-filter");

		const $filterInput = $closedUntilFilter.find("input");
		$filterInput.datepicker({
			dateFormat: "dd-mm-yy",
			onSelect: (stringDate) => {
				$(".js-closed-until-filter-calendar-icon").addClass(HIDDEN_ELEMENT_CLASS);
				$(".js-closed-until-filter-clear-icon").removeClass(HIDDEN_ELEMENT_CLASS);
				this._reloadListItems(stringDate);
			}
		});

		$(".js-closed-until-filter-calendar-icon").click(() => {
			$filterInput.datepicker("show");
		});

		$(".js-closed-until-filter-clear-icon").click(() => {
			$(".js-closed-until-filter-calendar-icon").removeClass(HIDDEN_ELEMENT_CLASS);
			$(".js-closed-until-filter-clear-icon").addClass(HIDDEN_ELEMENT_CLASS);
			$filterInput.val("");
			this._reloadListItems();
		});
	}

	static _reloadListItems(stringDate) {
		this.$listContainer.empty();
		const url = stringDate ? `${this.initialPageURL}?closed_until=${stringDate}` : this.initialPageURL;
		this._loadListItems(url);
	}

	static _createListBottomObserver() {
		return new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				this._loadListItems(this.nextPageURL);
			}
		}, {
			root: document.documentElement,
			threshold: 0.5
		});
	}

	static async _loadListItems(url) {
		const response = await axios.get(url);
		this.$listContainer.find(this.LOADER_SELECTOR).remove();
		this.$listContainer.find(this.LOAD_MORE_BUTTON_SELECTOR).remove();
		this.$listContainer.append(response.data.html);

		this.nextPageURL = response.data.next_page_url;
		if (this.nextPageURL) {
			this.setupLoadMoreItemsHandler();
		} else {
			this.$listContainer.append(this.$clonedEndOfList);
		}
	}

	static setupLoadMoreItemsHandler() {
		if (this.shouldUseInfiniteScroll()) {
			this._setupInfiniteScroll();
		} else {
			this._setupLoadMoreButton();
		}
	}

	static shouldUseInfiniteScroll() {
		return $(window).width() >= this.INFINITE_SCROLL_MIN_SCREEN_WIDTH;
	}

	/**
	 * Observes bottom of the consumer transactions list
	 * and loads new consumer transactions page (list items)
	 * when the bottom of the list is reached
	 */
	static _setupInfiniteScroll() {
		this.$listContainer.find(this.LOAD_MORE_BUTTON_SELECTOR).remove();
		this.$listContainer.append(this.$clonedLoader);
		const $loader = this.$listContainer.find(this.LOADER_SELECTOR);
		this.listBottomObserver.observe($loader.get(0));
	}

	static _setupLoadMoreButton() {
		this.$listContainer.find(this.LOADER_SELECTOR).remove();
		this.$listContainer.append(this.$clonedButton);
		this.$listContainer.find(this.LOAD_MORE_BUTTON_SELECTOR).click(() => {
			this._loadListItems(this.nextPageURL);
		});
	}

}
