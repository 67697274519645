import { Page } from "./_page";
import { preparePasswordFields } from "../_utils";

export class PasswordSetPage extends Page {

	static PAGE_CLASS = "password-set-page";

	constructor() {
		super();
		preparePasswordFields();
	}

}
