import $ from "jquery";
import { isOnMobile } from "../_utils";

export class TimeWidget {

	constructor(element) {
		this.uniqueClass = `time-widget__${Date.now()}`;
		this.uniqueSelector = `.${this.uniqueClass}`;
		this.$widget = $(element);
		this.$widget.addClass(this.uniqueClass);
		this.$optionsContainer = this.$widget.find(".js-time-widget-options");
		this.$allOptions = this.$widget.find(".js-time-widget-option-item");
		this.$input = this.$widget.find(".js-time-widget-input");
		this.$input.attr("type", "text");
		this._markSelectedOption();
		if (isOnMobile()) {
			this._prepareWidgetClickHandler();
		} else {
			this._prepareWidgetHoverHandler();
		}
		this._prepareOptionHandlers();
	}

	_markSelectedOption() {
		const $selectedOption = this._getSelectedOption();
		$selectedOption.siblings().removeClass("time-widget__option-item--selected");
		$selectedOption.addClass("time-widget__option-item--selected");
	}

	_getSelectedOption() {
		const inputValue = this.$input.val().substring(0, 5);
		return this.$widget.find(`.js-time-widget-option-item[data-value="${inputValue}"]`);
	}

	_prepareWidgetClickHandler() {
		this.$widget.click(() => {
			this._toggleOptions();
			setTimeout(() => $(document).one("click", (event) => {
				if (this._hasVisibleOptions() && !this._isClicked(event.target)) this._toggleOptions();
			}), 10);
		});
	}

	_prepareWidgetHoverHandler() {
		this.$widget.mouseenter(() => {
			this._openOptions();
		});
		this.$widget.mouseleave(() => {
			this._closeOptions();
		});
	}

	_hasVisibleOptions() {
		return this.$optionsContainer.css("display") !== "none";
	}

	_isClicked(clickEventTarget) {
		const $clickedElement = $(clickEventTarget);
		return !!(
			$clickedElement.hasClass(this.uniqueSelector)
			|| $clickedElement.closest(this.uniqueSelector).length
		);
	}

	_prepareOptionHandlers() {
		const self = this;
		this.$allOptions.click(function() {
			self._updateInputValue($(this).html().trim());
			self._markSelectedOption();
		});
		this.$allOptions.hover(function() {
			$(this).toggleClass("time-widget__option-item--hovering");
		});
	}

	_updateInputValue(value) {
		this.$input.val(value);
		this.$input.trigger("input");
		this.$input.trigger("change");
	}

	_toggleOptions() {
		if (this._hasVisibleOptions()) {
			this._closeOptions();
		} else {
			this._openOptions();
		}
	}

	_closeOptions() {
		this.$widget.find(".js-time-widget-dropdown-arrow").removeClass("rotate");
		this.$optionsContainer.slideUp(10);
	}

	_openOptions() {
		this.$widget.find(".js-time-widget-dropdown-arrow").addClass("rotate");
		this.$optionsContainer.slideDown(10, () => this._scrollSelectedOptionIntoView());
	}

	_scrollSelectedOptionIntoView() {
		const $selectedOption = this.$optionsContainer.find(".time-widget__option-item--selected").get(0);
		if ($selectedOption) {
			$selectedOption.scrollIntoView({
				block: "nearest",
				inline: "center"
			});
		}
	}

	disable() {
		this.$widget.unbind("mouseenter mouseleave click");
		this.$allOptions.unbind("mouseenter mouseleave click");
		this.$allOptions.css("cursor", "wait");
		this.$widget.find(".js-time-widget-dropdown-arrow").css("cursor", "wait");
	}

}
