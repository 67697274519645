import $ from "jquery";
import { Page } from "./_page";
import { isElementInViewport } from "../../_utils";


export class FAQPage extends Page {

	static PAGE_CLASS = "faq-page";

	constructor() {
		super();
		this._prepareFAQItems();
	}

	_prepareFAQItems() {
		$(".js-faq-item").click((event) => {
			const $faqItem = $(event.target).closest(".js-faq-item");
			$faqItem.find(".js-faq-item-arrow").toggleClass("rotate");

			const $faqItemBody = $faqItem.find(".js-faq-item-body");
			const slideToggleDuration = this._calculateFAQItemBodySlideToggleDuration($faqItemBody.height());
			$faqItemBody.slideToggle(slideToggleDuration);

			this._scrollIntoView($faqItem.get(0), slideToggleDuration / 1.2);
		});
	}

	_scrollIntoView(element, delayDuration) {
		setTimeout(function() {
			if (!isElementInViewport(element)) {
				element.scrollIntoView({
					block: "center",
					behavior: "smooth"
				});
			}
		}, delayDuration);
	}

	_calculateFAQItemBodySlideToggleDuration(faqBodyHeight) {
		if (faqBodyHeight < 45) return 450;
		if (faqBodyHeight < 90) return faqBodyHeight * 10;
		return 900;
	}

}
