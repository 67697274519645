import { Page } from "./_page";
import { PasswordForgottenModal } from "../_password_forgotten_modal";

export class LoginPage extends Page {

	static PAGE_CLASS = "login-page";

	constructor() {
		super();
		this.addModal(new PasswordForgottenModal());
	}

}
