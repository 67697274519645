import $ from "jquery";
import axios from "axios";

export function preparePasswordFields() {
	const $passwordInputs = $(".js-password-form-field").find("input");
	$("<div class=\"password__char-count\">0/8</div>").insertAfter($passwordInputs);
	$passwordInputs.on("keyup", (event) => {
		$(event.target).siblings(".password__char-count").text(`${event.target.value.length}/8`);
	});
}

export function prepareLocationAreaClickHandlers() {
	const portalsData = $(".js-portals-data").data();
	$(".js-location-area").on("click", async function() {
		const data = {
			planned_location_area: $(this).data("locationAreaId")
		};
		await axios.patch(portalsData.updateShoppingCartUrl, data);
		window.location.href = portalsData.locationsPageUrl;
	});
}
