import $ from "jquery";
import axios from "axios";
import { HIDDEN_ELEMENT_CLASS } from "../../_common";
import { Page } from "./_page";
import { CollapsableCardService } from "../_collapsable_card_service";
import { LoginModal } from "../_login_modal";
import { PasswordForgottenModal } from "../_password_forgotten_modal";
import { ReservationFormService } from "../_reservation_form_service";
import { paintSVGs } from "../../_utils";

export class ProductPage extends Page {

	static PAGE_CLASS = "product-page";

	constructor() {
		super();
		this.unappliedDiscountCode = "";
		this._setup();
	}

	_setup() {
		// eslint-disable-next-line no-new
		new CollapsableCardService();
		this.reservationFormService = new ReservationFormService();
		this._prepareReservationForm();
		this._prepareSummarySection();
	}

	_prepareReservationForm() {
		if (this._shouldRenderAuthForms()) {
			this.addModal(new LoginModal());
			this.addModal(new PasswordForgottenModal());
		}
		this._prepareAddLicensePlateButton();
		this._prepareExistingLicensePlateFields();
		this._prepareFormFieldListeners();
		this._prepareFormButtonListeners();
	}

	_shouldRenderAuthForms() {
		return (
			this.reservationFormService.$form.hasClass("js-for-anonymous-user")
			&& !this.reservationFormService.$form.hasClass("js-for-editing-reservation")
		);
	}

	_prepareAddLicensePlateButton() {
		$(".js-add-license-plate-button").on("click", function() {
			$(this).addClass(HIDDEN_ELEMENT_CLASS);
			$(".license-plate--selected").removeClass("license-plate--selected");
			$(".js-license-plate-field").removeClass(HIDDEN_ELEMENT_CLASS).addClass("license-plate--selected");
			$(".js-existing-license-plate-input").val("");
		});
	}

	_prepareExistingLicensePlateFields() {
		$(".js-existing-license-plate").on("click", function() {
			const $selectedLicensePlateField = $(this);
			const $previouslySelectedLicensePlates = $(".license-plate--selected");
			$previouslySelectedLicensePlates.removeClass("license-plate--selected");
			$selectedLicensePlateField.addClass("license-plate--selected");
			$(".js-existing-license-plate-input").val($selectedLicensePlateField.data("licensePlateValue"));
		});
	}

	_prepareFormFieldListeners() {
		const self = this;
		self.reservationFormService.$form.on("datetime_change", async function(event) {
			self._shimmerSelectionSummary();
			$(event.input).addClass("shimmer");
			await self._submitFormDataWithAJAX();
		});

		$("select[name=location_area]").on("change", async function() {
			self._shimmerSelectionSummary();
			$(this).addClass("shimmer");
			$("select[name=location_settings]").addClass("shimmer");
			await self._submitFormDataWithAJAX();
		});

		$("select[name=location_settings]").on("change", async function() {
			self._shimmerSelectionSummary();
			$(this).addClass("shimmer");
			await self._submitFormDataWithAJAX();
		});
	}

	_shimmerSelectionSummary() {
		$(".js-selection-summary .js-shimmerable-text").addClass("shimmer");
		$(".js-selection-summary")
			.find(".js-shimmerable-text, .js-shimmerable-text__nested")
			.css("color", "transparent");
		paintSVGs();
	}

	_prepareFormButtonListeners() {
		$(".js-apply-discount-code-button").on("click", async() => {
			this._applyDiscountCode();
			const $discountCodeInput = $(".js-discount-code-input");
			if ($discountCodeInput.val()) {
				$discountCodeInput.addClass("shimmer");
				await this._submitFormDataWithAJAX();
			}
		});

		$(".js-reservation-form-submit-button").on("click", async() => {
			this._applyDiscountCode();
			this._beforeSubmit();
			this.reservationFormService.$form.trigger("submit");
		});
	}

	_applyDiscountCode() {
		this.unappliedDiscountCode = "";
		const $discountCodeInput = $(".js-discount-code-input");
		$discountCodeInput.val($discountCodeInput.val().trim().toUpperCase());
		$discountCodeInput.addClass("js-applied-discount-code");
	}

	async _submitFormDataWithAJAX() {
		this._beforeSubmit();
		this._shimmerPricesSummary();
		const reservationFormData = this.reservationFormService.getFormData();
		const response = await axios.post(window.location.href, reservationFormData);
		$(".js-product-page-content-container").html(response.data.product_page_content);
		const $discountCodeInput = $(".js-discount-code-input");
		if (this.unappliedDiscountCode && !$discountCodeInput.hasClass("js-applied-discount-code")) {
			$discountCodeInput.val(this.unappliedDiscountCode);
		}
		this._setup();
	}

	_beforeSubmit() {
		this._disableFieldsAndButtons();
		const $discountCodeInput = $(".js-discount-code-input");
		if ($discountCodeInput.hasClass("js-applied-discount-code")) {
			this._appendDiscountCodeFieldToReservationForm($discountCodeInput);
		} else {
			this.unappliedDiscountCode = $discountCodeInput.val();
		}
	}

	_shimmerPricesSummary() {
		$(".js-prices-summary .js-shimmerable-text").addClass("shimmer");
		$(".js-prices-summary").find(".js-shimmerable-text, .js-shimmerable-text__nested").css("color", "transparent");
	}

	_disableFieldsAndButtons() {
		this.reservationFormService.disableForm();
		$(".js-discount-code-input").attr("readonly", true).css("cursor", "wait");
		$(".js-apply-discount-code-button").attr("disabled", true).css("cursor", "wait");
		$(".js-remove-discount-code-button").css("cursor", "wait").unbind("click");
	}

	_appendDiscountCodeFieldToReservationForm($discountCodeInput) {
		const $discountCodeInputClone = $discountCodeInput.clone();
		$discountCodeInputClone.addClass(HIDDEN_ELEMENT_CLASS);
		this.reservationFormService.$form.append($discountCodeInputClone);
	}

	_prepareSummarySection() {
		$(".js-pricing-details-button").on("click", function() {
			$(".js-pricing-details-item").toggleClass(HIDDEN_ELEMENT_CLASS);
			$(this).find(".js-label-show, .js-label-hide").toggleClass(HIDDEN_ELEMENT_CLASS);
		});
		$(".js-remove-discount-code-button").on("click", async() => {
			$(".js-discount-code-input").val("");
			await this._submitFormDataWithAJAX();
		});
	}
}
