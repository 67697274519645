import { Modal } from "./_modal";
import { FormService } from "../_form_service";

export class LoginModal extends Modal {

	constructor() {
		super(".js-login-modal", ".js-open-login-modal");
		this._prepareLoginForm();
	}

	_prepareLoginForm() {
		const loginFormService = new FormService(".js-login-form");
		loginFormService.$form.on("submit", async(event) => {
			event.preventDefault();
			await loginFormService.submit();
			if (!loginFormService.hasErrors()) {
				this.close();
				window.location.reload();
			}
		});
	}

}
