import $ from "jquery";
import { Page } from "./_page";
import { CollapsableCardService } from "../_collapsable_card_service";
import { preparePasswordFields } from "../_utils";

export class CheckoutCredentialsPage extends Page {

	static PAGE_CLASS = "checkout-credentials-page";

	constructor() {
		super();
		// eslint-disable-next-line no-new
		new CollapsableCardService();
		this._prepareFormHandlers();
		this._prepareCreateAccountCheckbox();
		preparePasswordFields();
	}

	_prepareFormHandlers() {
		const selectedClass = "selected-personal-info-box";
		const $residentInfoData = $(".js-resident-info-data");
		const $guestInfoData = $(".js-guest-info-data");
		$residentInfoData.click(function() {
			$(this).addClass(selectedClass);
			$guestInfoData.removeClass(selectedClass);
		});
		$guestInfoData.click(function() {
			$(this).addClass(selectedClass);
			$residentInfoData.removeClass(selectedClass);
		});

		$(".js-continue-checkout-button").click(function() {
			if ($guestInfoData.hasClass(selectedClass)) {
				$("#should-use-resident-info").remove();
			}
			$(".js-checkout-personal-info-form").submit();
		});

	}

	_prepareCreateAccountCheckbox() {
		const $passwordField = $(".js-password-form-field");
		const $shouldCreateAccountInput = $(".js-should-create-account-field").find("input");

		$shouldCreateAccountInput.is(":checked")
			? $passwordField.css("display", "block")
			: $passwordField.css("display", "none");

		$shouldCreateAccountInput.on("click", function() {
			$passwordField.slideToggle();
		});
	}
}
