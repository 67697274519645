import $ from "jquery";
import axios from "axios";
import { ReservationFormService } from "../_reservation_form_service";
import { Page } from "./_page";
import { isOnMobile, paintSVGs } from "../../_utils";
import { CollapsableCardService } from "../_collapsable_card_service";
import { HIDDEN_ELEMENT_CLASS } from "../../_common";

export class LocationsPage extends Page {

	static PAGE_CLASS = "js-locations-page";

	constructor() {
		super();
		this.$contentContainer = $(".js-locations-page-content");
		this.contentURL = this.$contentContainer.data("locationsPageContentUrl");
		this.hasSubmittedFormData = false;
		this._loadPageContent();
	}

	async _loadPageContent() {
		this._prepareForm();
		this.reservationFormService.disableForm();
		const response = await axios.get(this.contentURL);
		this.$contentContainer.html(response.data.locations_page_content);
		this._postLoadPageContent();
	}

	_prepareForm() {
		this.reservationFormService = new ReservationFormService();
		if (isOnMobile()) {
			// eslint-disable-next-line no-new
			new CollapsableCardService();
		}
		if (!isOnMobile() || this.hasSubmittedFormData) {
			$(".js-locations-availability-form-container").removeClass(HIDDEN_ELEMENT_CLASS);
		}
	}

	_postLoadPageContent() {
		this._prepareForm();
		this._prepareFormFields();
	}

	_prepareFormFields() {
		const self = this;
		self.reservationFormService.$form.on("datetime_change", async function(event) {
			$(event.input).addClass("shimmer");
			self._activateLocationItemShimmer();
			await self._submitFormDataWithAJAX();
			$(event.input).removeClass("shimmer");
			self._deactivateLocationItemShimmer();
		});
		$("select[name=location_area]").on("change", async function() {
			$(this).addClass("shimmer");
			self._activateLocationItemShimmer();
			await self._submitFormDataWithAJAX();
			$(this).removeClass("shimmer");
			self._deactivateLocationItemShimmer();
		});
	}

	_activateLocationItemShimmer() {
		const $locationItemShimmerableElement = $(".js-location-item-shimmerable-element");
		$locationItemShimmerableElement.addClass("shimmer");
		paintSVGs();
	}

	_deactivateLocationItemShimmer() {
		$(".js-location-item-shimmerable-element").removeClass("shimmer");
	}

	async _submitFormDataWithAJAX() {
		this.hasSubmittedFormData = true;
		this.reservationFormService.disableForm();
		const response = await axios.post(this.contentURL, this.reservationFormService.getFormData());
		this.$contentContainer.html(response.data.locations_page_content);
		this._postLoadPageContent();
	};

}
