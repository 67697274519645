import $ from "jquery";
import { Page } from "./_page";
import { ReservationFormService } from "../_reservation_form_service";
import { prepareLocationAreaClickHandlers } from "../_utils";
import { ConsumerReservations } from "../consumer_reservations";

export class HomePage extends Page {

	static PAGE_CLASS = "home-page";

	constructor() {
		super();
		// eslint-disable-next-line no-new
		new ReservationFormService();
		this._preparePricingAndBenefitsSlider();
		prepareLocationAreaClickHandlers();
		ConsumerReservations.prepareCards();
	}

	_preparePricingAndBenefitsSlider() {
		$(".js-pricing-and-benefits-anchor").on("click", (e) => {
			const $element = $(`#${e.target.value}`)[0];
			if ($element.scrollIntoViewIfNeeded) {
				$element.scrollIntoViewIfNeeded(false);
			} else {
				try {
					$element.scrollIntoView({ block: "nearest" });
				} catch (error) {
					$element.scrollIntoView(false);
				}
			}
		});

		const observer = new IntersectionObserver((entries, _) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					$(`.js-pricing-and-benefits-anchor[value|='${entry.target.id}']`).prop("checked", true);
				}
			});
		}, { root: $("main")[0], rootMargin: "0px", threshold: 0.45 });

		$(".js-pricing-and-benefits-card").each((_, element) => observer.observe(element));
	}

}
