import "../../scss/consumers/index.scss";
import $ from "jquery";
import "jquery-ui/ui/i18n/datepicker-nl";
import "jquery-ui/ui/i18n/datepicker-de";
import { paintSVGs, prepareFlashMessages, prepareNestedMobileMenus, setupAxios } from "../_utils";
import { Page, pageClasses } from "./pages";
import { SentryService } from "../_sentry_service";

setupAxios();

$(document).ready(() => {
	const CurrentPageClass = pageClasses.find(page => page.isCurrentPage()) || Page;
	// eslint-disable-next-line no-new
	new CurrentPageClass();

	prepareFlashMessages();
	prepareNestedMobileMenus();
	paintSVGs();

	SentryService.init();
});

$(window).on("load", () => {
	$("html").addClass("loaded");
});
