import { Page } from "./_page";
import { prepareLocationAreaClickHandlers } from "../_utils";

export class LocationAreasPage extends Page {

	static PAGE_CLASS = "js-location-areas-page";

	constructor() {
		super();
		prepareLocationAreaClickHandlers();
	}

}
