import $ from "jquery";
import { Page } from "./_page";
import { ConsumerTransactions } from "../_consumer_transactions";


export class MyAccountPage extends Page {

	static PAGE_CLASS = "js-my-account-page";

	constructor() {
		super();
		this._preparePageContainerCSSClasses();
		this.enableReloadOnReturn();
		ConsumerTransactions.initList();
		this._prepareWindowResizeHandler();
	}

	_prepareWindowResizeHandler() {
		$(window).resize(() => {
			if (ConsumerTransactions.nextPageURL) {
				ConsumerTransactions.setupLoadMoreItemsHandler();
			}
			this._preparePageContainerCSSClasses();
		});
	}

	_preparePageContainerCSSClasses() {
		if (ConsumerTransactions.shouldUseInfiniteScroll()) {
			$(".js-my-account-page-container").removeClass("page-container--column");
		} else {
			$(".js-my-account-page-container").addClass("page-container--column");
		}
	}

}
